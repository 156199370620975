var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"{banner.color}","dark":""}},[_c('div',{staticClass:"align-center"},[_c('h1',[_vm._v(_vm._s(_vm.banner.title))])]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();_vm.banner.drawer = !_vm.banner.drawer}}},[_c('v-app-bar-nav-icon'),_vm._v(" 切换 ")],1)],1),_c('v-main',[_c('v-navigation-drawer',{staticClass:"pa-0 pt-2 pb-12 mt-12",staticStyle:{"min-width":"60px"},attrs:{"fixed":"","clipped":""},model:{value:(_vm.banner.drawer),callback:function ($$v) {_vm.$set(_vm.banner, "drawer", $$v)},expression:"banner.drawer"}},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" 切换 ")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.origin.all),function(o){return _c('v-list-item',{key:o.origin,attrs:{"link":""},on:{"click":() => (
              (_vm.origin.stats = 0),
              (_vm.origin.current = o),
              (_vm.banner.drawer = false)
            )}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-star")])],1),_c('v-list-item-title',[_vm._v(_vm._s(o.name)+"排行榜")])],1)}),_c('v-divider'),_vm._l((_vm.origin.all),function(o){return _c('v-list-item',{key:o.origin,attrs:{"link":""},on:{"click":() => (
              (_vm.origin.stats = 1),
              (_vm.origin.current = o),
              (_vm.banner.drawer = false)
            )}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-star")])],1),_c('v-list-item-title',[_vm._v(_vm._s(o.name)+"成绩分布")])],1)})],2)],1),_c('v-container',{staticClass:"fill-height mt-2 mb-2",attrs:{"fluid":""}},[(!_vm.origin.stats)?_c('Ranklist',{attrs:{"origin":_vm.origin.current,"mode":_vm.mode}}):_vm._e(),(_vm.origin.stats)?_c('Statistics',{attrs:{"origin":_vm.origin.current,"mode":_vm.mode}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }