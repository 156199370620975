<template>
  <v-app>
    <v-app-bar app color="{banner.color}" dark>
      <div class="align-center">
        <h1>{{ banner.title }}</h1>
      </div>
      <v-btn text @click.stop="banner.drawer = !banner.drawer">
        <v-app-bar-nav-icon /> 切换
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-navigation-drawer
        class="pa-0 pt-2 pb-12 mt-12"
        v-model="banner.drawer"
        fixed
        clipped
        style="min-width: 60px"
      >
        <v-list>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="title"> 切换 </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list nav dense>
          <v-list-item
            link
            v-for="o in origin.all"
            :key="o.origin"
            @click="
              () => (
                (origin.stats = 0),
                (origin.current = o),
                (banner.drawer = false)
              )
            "
          >
            <v-list-item-icon> <v-icon>mdi-star</v-icon> </v-list-item-icon>
            <v-list-item-title>{{ o.name }}排行榜</v-list-item-title>
          </v-list-item>

          <v-divider />

          <v-list-item
            link
            v-for="o in origin.all"
            :key="o.origin"
            @click="
              () => (
                (origin.stats = 1),
                (origin.current = o),
                (banner.drawer = false)
              )
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ o.name }}成绩分布</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-container class="fill-height mt-2 mb-2" fluid>
        <Ranklist v-if="!origin.stats" :origin="origin.current" :mode="mode" />
        <Statistics v-if="origin.stats" :origin="origin.current" :mode="mode" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Ranklist from "./components/Ranklist";
import Statistics from "./components/Statistics";
import { Watermark } from "@pansy/watermark";
const watermark = new Watermark({
  text: "1970-01-01 00:00:00",
  blindFontSize: 40,
});
watermark.show();
export default {
  name: "App",

  components: { Ranklist, Statistics },

  data: () => {
    const mode = "THUSCC24";
    const title = "THUSCC 2024";
    const origins = {
      THUSCC24: [{ id: 0, name: "" }],
    };
    const data = {
      mode,
      banner: { title, color: "#000000", drawer: false },
      origin: { current: { id: 0, name: "" }, stats: 0, all: origins[mode] },
      watermark: {
        title: title,
        color: {
          now: [0x20, 0x20, 0x20],
          min: [0x20, 0x20, 0x20],
          max: [0xe0, 0xe0, 0xe0],
          dlt: [1, 1, 1],
        },
      },
    };
    return data;
  },
  methods: {
    tickWatermark() {
      const timeStr = new Date().toLocaleString();
      watermark.update({
        text: [this.watermark.title, timeStr],
        fontSize: 24,
        gapX: 600,
        gapY: 300,
      });
      let app = document.querySelector("header");
      const idx = Math.floor(Math.random() * 3);
      this.watermark.color.now[idx] += this.watermark.color.dlt[idx];
      if (
        this.watermark.color.now[idx] > this.watermark.color.max[idx] ||
        this.watermark.color.now[idx] < this.watermark.color.min[idx]
      )
        this.watermark.color.dlt[idx] = -this.watermark.color.dlt[idx];
      app.style.backgroundColor = `rgb(${this.watermark.color.now[0]},${this.watermark.color.now[1]},${this.watermark.color.now[2]})`;
    },
  },
  mounted: async function () {
    setInterval(this.tickWatermark, 50);
  },
};
</script>
