<template>
  <v-container>
    <v-row class="text-center aligh-center">
      <v-card class="mx-auto" width="90%">
        <v-row class="align-center" style="height: 100px">
          <v-spacer />
          <!--v-img width="10%"  src="CCSP_Y.jpeg"/-->
          <v-spacer />
          <h1>{{ origin.name }}排行榜</h1>
          <v-spacer />
          <!--v-img width="10%"  class="mx-auto" src="MIAO.jpeg"/-->
          <v-spacer />
        </v-row>
        <!-- <v-card-text><v-text-field v-model="searchinfo" label="搜索" single-line hide-details></v-text-field></v-card-text> -->
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="searchinfo"
          :items-per-page="20"
          :options.sync="options"
          :server-items-length="totalDesserts"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [20, 100, -1] }"
          class="elevation-1"
        >
          <template v-slot:item.rank="{ item }">
            <!-- <v-chip :color="getColor(item.rank)" :dark="getDark(item.rank)"> -->
            <v-chip>
              {{ item.rank }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Ranklist",
  props: ["origin", "mode"],
  data: () => ({
    mode: "unknown",
    totalDesserts: 0,
    loading: true,
    options: {},
    headers: [],
    desserts: [],
    problems: [],
    scoreBase: 0,
  }),
  mounted: async function () {
    const scoreBases = { THUSCC24: 1000 };
    const problems = {
      THUSCC24: [
        { name: "HPL", ratio: 0.2, perf_ratio: 0.8, perf_suffix: " Gflops" },
        { name: "CGFDM", ratio: 0.3, perf_ratio: 0.8, perf_suffix: " sec" },
        { name: "NEKO1", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO2", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO3", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO4", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO5", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO6", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO7", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO8", ratio: 0.05, perf_ratio: 0 },
      ],
    };
    const commonHeader = [
      { text: "排名", align: "center", sortable: false, value: "rank" },
      { text: "队名", align: "center", sortable: false, value: "teamName" },
      { text: "总分", align: "center", sortable: false, value: "totalScore" },
    ];
    const headers = {
      THUSCC24: [...commonHeader],
    };
    this.scoreBase = scoreBases[this.mode];
    this.problems = problems[this.mode];
    this.headers = headers[this.mode].concat(
      problems[this.mode].map(({ name, ratio }) => {
        return {
          text: `${name} (${ratio * 100}%)`,
          value: name,
          align: "center",
          sortable: false,
        };
      })
    );
    console.log(this.headers);
    await this.getDataFromApi();
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    origin: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    // getColor(rank) {
    //   if (rank == 1) return "yellow";
    //   else if (rank <= 3) return "grey";
    //   else if (rank <= 8) return "brown";
    //   else return "white";
    // },
    // getDark(rank) {
    //   if (rank <= 8) return true;
    //   return false;
    // },
    async getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const query = {
        size: itemsPerPage,
        page: page,
        origin: this.origin.id,
      };
      const queryString = new URLSearchParams(query).toString();
      const that = this;
      this.$http.get("/api/ranklist?" + queryString).then((res) => {
        that.desserts = res.data.data;
        that.totalDesserts = res.data.total;
        that.desserts.forEach((item) => {
          let totalScore = 0;
          that.problems.forEach(({ name, ratio, perf_ratio, perf_suffix }) => {
            const score = item.scores[name];

            if (score == null) item[name] = "-";
            else if (perf_ratio == 0) {
              const correct = that.scoreBase * score * ratio;
              totalScore += correct;
              item[name] = correct.toFixed(0);
            } else {
              const perfScore = item.perfScores[name] ?? 0;
              const perfValue = item.perfValues[name] ?? 0;
              const correct = that.scoreBase * score * ratio * (1 - perf_ratio);
              const perf = that.scoreBase * perfScore * ratio * perf_ratio;
              totalScore += correct + perf;
              item[name] = `${correct.toFixed(0)} + ${perf.toFixed(
                2
              )} (${perfValue} ${perf_suffix})`;
            }
          });
          item.totalScore = totalScore.toFixed(2);
        });
        that.loading = false;
      });
      setTimeout(this.getDataFromApi, 60 * 1000);
    },
  },
};
</script>
