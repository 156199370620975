<template>
  <v-container>
    <v-row class="text-center aligh-center">
      <v-card class="mx-auto" width="90%">
        <v-row class="align-center" style="height: 100px">
          <v-spacer />
          <!--v-img width="10%"  src="CCSP_Y.jpeg"/-->
          <v-spacer />
          <h1>{{ origin.name }}分数分布</h1>
          <v-spacer />
          <v-spacer />
        </v-row>
        <div
          class="echart"
          id="scoreStatistics"
          :style="scoreStatisticsStyle"
        ></div>
        <!-- <v-card-actions>
          v-btn color="primary"  @click="search()">刷新</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Statistics",
  props: ["origin", "mode"],
  data: () => ({
    staticData: {},
    scoreStatisticsStyle: { float: "left", width: "100%", height: "700px" },
    loading: true,
    options: {},
    desserts: [],
    problems: [],
    scoreBase: 0,
  }),
  mounted: async function () {
    const scoreBases = { THUSCC24: 1000 };
    const problems = {
      THUSCC24: [
        { name: "HPL", ratio: 0.2, perf_ratio: 0.8, perf_suffix: " Gflops" },
        { name: "CGFDM", ratio: 0.3, perf_ratio: 0.8, perf_suffix: " sec" },
        { name: "NEKO1", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO2", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO3", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO4", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO5", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO6", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO7", ratio: 0.05, perf_ratio: 0 },
        { name: "NEKO8", ratio: 0.05, perf_ratio: 0 },
      ],
    };
    this.problems = problems[this.mode];
    this.scoreBase = scoreBases[this.mode];
    await this.getDataFromApi();
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    origin: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const labels = [...this.problems.map(({ name }) => name), "总分"];
      const series = labels.map((name) => ({
        type: "line",
        lineStyle: { width: 2.5 },
        smooth: true,
        symbolSize: 0,
        data: [],
        name,
        label: { show: false, position: "top" },
      }));

      let query = { size: -1, page: -1, origin: this.origin.id };
      const queryString = new URLSearchParams(query).toString();
      const that = this;
      this.staticData = {
        xAxis: { name: "人数", data: [] },
        legend: { data: labels, top: "bottom" },
        yAxis: { name: "分数" },
        series,
      };
      this.$http.get("/api/ranklist?" + queryString).then((res) => {
        that.desserts = res.data.data.sort((a, b) => b.rank - a.rank);
        that.totalDesserts = res.data.total;
        that.staticData.xAxis.data = [];
        labels.forEach((_, index) => {
          that.staticData.series[index].data = [];
        });
        that.desserts.forEach((item) => {
          that.staticData.xAxis.data.push(item.rank);

          let totalScore = 0;
          that.problems.forEach(({ name, ratio, perf_ratio }, index) => {
            const score = item.scores[name],
              perfScore = item.perfScores[name] ?? 0;
            let itemScore = 0;
            if (score != null) {
              if (perf_ratio == 0) {
                itemScore = that.scoreBase * score * ratio;
              } else {
                itemScore =
                  that.scoreBase *
                  (score * ratio * (1 - perf_ratio) +
                    perfScore * ratio * perf_ratio);
              }
            }
            totalScore += itemScore;
            that.staticData.series[index].data.push(itemScore);
          });
          that.staticData.series[that.problems.length].data.push(totalScore);
        });
        that.staticData.series.forEach((series) => {
          series.data.sort((a, b) => b - a);
        });
        const myChart = echarts.init(
          document.getElementById("scoreStatistics")
        );
        myChart.setOption(that.staticData);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
        that.loading = false;
      });
      setTimeout(this.getDataFromApi, 60 * 1000);
    },
    updateData() {
      const myChart = echarts.init(document.getElementById("scoreStatistics"));
      myChart.setOption(this.staticData);
    },
  },
};
</script>
